.rule-panel {
    width: 135px;
    border: 1px solid var(--theme-color);
    margin: 5px;

    position: relative;
    overflow: hidden;

    text-align: center;
    min-height: 150px;

    font-size: 12px;
}

.rule-panel.dim:before, .rule-panel.dim:after {
    position: absolute;
    content: '';
    background: red;
    display: block;
    width: 100%;
    height: 10px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.rule-panel.dim:after {
    -webkit-transform: rotate(45deg);    
    transform: rotate(45deg);
}

.rule-panel img {
    max-width: 100%;
}

.rule-panel img.hover {
    opacity: 10%;
}

.rule-panel.dim img {
    opacity: 20%;
}

.scenario-difficulty {
    position: absolute;
    text-align: left;
    width: 100%;
}

.rule-data {
    position: relative;
}

.rule-data > * {
    position: absolute;
}

.rule-data > img {
    left: 0;
}

.scenario-difficulty {
    font-size: 22px;
    margin: 5px;
}

.adversary-boxes {
    display: flex;
    flex-wrap: wrap;
}

.adversary-boxes:first-child {
    display: flex;
    flex-wrap: wrap;
}

.adversary-difficulty-box {
    flex: 1 1 33%;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--theme-color);
    box-sizing: border-box;
}

.adversary-difficulty-box:first-of-type {
    flex: 1 0 100%;
}

.adversary-difficulty-box.hover {
    border: 1px solid white;
}