.spirit-page {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.spirit-page > div {
    flex: 1 1 100%;
}

.spirit-page > .unchosen-side {
    flex: 1 0 45%;
}

.unchosen-side, .chosen-side {
    display: flex;
    flex-direction: column;
}

.drop-area {
    flex-grow: 1;
}

.unchosen-area, .chosen-area {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;

    border: 2px dashed var(--theme-color);
    border-radius: 15px;

    min-height: 100%;

    margin: 0 5px;
}

.slider {
    margin-bottom: 10px;
    position: relative;
}
.slider > span {
    margin: 0 5px;
}
.slider > input {
    transform: translate(0, 2px);
}

.parameter-choice {
    cursor: pointer;
    padding: 0 3px;
    margin: 2px;
    border-bottom: 2px solid var(--theme-color);
}

.parameter-choice.chosen {
    background-color: var(--theme-color);
}

.numbers span {
    font-size: 24px;
    margin: 0 5px;
}

.randomer > * {
    margin: 20px 5px;
}

.drop-area-heading {
    font-size: 20px;
    text-align: center;
}

.show-parameter-area {
    margin: 5px 10px;
}

.randomer-execute {
    margin-top: 40px;
}
.randomer-button {
    background-color: var(--theme-color);
    border-radius: 10px;
    padding: 5px 20px;
    cursor: pointer;
}

.show-parameter-button {
    margin: 10px 10px 15px;
}

.spirit-stat-list {
    display: flex;
    margin: 0 5px;
}

.spirit-stat-list > div {
    margin: 1px 5px;
}

.spirit-stat-values {
    flex: 1;
}

.mad-result, .stats-heading {
    margin: 5px;
}

.dragging-over .chosen-area, .dragging-over .unchosen-area {
    background-color: var(--theme-color);
}
