.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 50px;
    align-items: center;
    font-size: 36px;
    border-bottom: 3px solid var(--theme-color);
}

.navbar a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-decoration: none;
}

.navbar a.active {
    background-color: var(--theme-color);
}